import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Sun({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M8.0014 11.7143C10.0527 11.7143 11.7157 10.0513 11.7157 7.99999C11.7157 5.94865 10.0527 4.28571 8.0014 4.28571C5.95005 4.28571 4.28711 5.94865 4.28711 7.99999C4.28711 10.0513 5.95005 11.7143 8.0014 11.7143Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle d="M8 0.571411V1.71427" stroke={color} strokeRound />
      <BaseIconPathStyle d="M8 14.2857V15.4286" stroke={color} strokeRound />
      <BaseIconPathStyle d="M15.43 8H14.2871" stroke={color} strokeRound />
      <BaseIconPathStyle
        d="M1.71512 7.99997H0.572266"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M13.2587 2.74286L12.4473 3.55429"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M3.55557 12.4457L2.74414 13.2571"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M13.2587 13.2571L12.4473 12.4457"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M3.55557 3.55429L2.74414 2.74286"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default Sun;
